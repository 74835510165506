<template>
  <b-card-code title="">
    <div class="row mb-2">
      <div class="col-lg-6">
        <h4>Felhasználók listája</h4>
      </div>
      <div class="col-lg-6 text-right">
        <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push('/user/create')">Felhasználó létrehozás</button>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item v-b-modal.modal-edit @click="loadUserData(props.row.id)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Szerkesztés</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteThisUser(props.row.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Törlés</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal id="modal-edit" ref="modal-edit" title="Felhasználói adatok szerkesztése" hide-footer>
      <b-row>
        <b-col md="8">
          <b-form-group
            label="Profil kép feltöltés"
            label-for="profile-image"
          >
            <b-form-file
              id="profile-image"
              v-model="file"
              @change="onFileChange"
              type="file"
              placeholder="Profil kép feltöltése"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <br>
          <button class="btn btn-primary" @click="uploadImage(selectedID)">Feltöltés</button>
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          <h5 class="mb-0">
            Felhasználói adatai
          </h5>
          <small class="text-muted">
            Kérlek add meg a cserélni kívánt adatokat.
          </small>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Név"
            label-for="v-username"
          >
            <b-form-input
              id="v-username"
              v-model="name"
              placeholder="Szakállas Róbert"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="E-mail cím"
            label-for="v-email"
          >
            <b-form-input
              v-model="email"
              id="v-email"
              type="email"
              placeholder="szakallas.robert@debszoftver.hu"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Telefonszám"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="phone"
              type="phone"
              placeholder="Telefonszám"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Új jelszó megadása"
            label-for="v-password"
          >
            <b-form-input
              id="v-password"
              v-model="password"
              type="password"
              placeholder="W?9rK_cv"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <br>
          <button class="btn btn-primary" @click="updatePassword()">Jelszó frissítése</button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <p>Engedélyek</p>
        </b-col>
        <b-col v-for="(role, index) in roles" :key="index" md="12">
          <div class="d-flex" v-if="role.value">
            <b-form-checkbox
              :id="'v-role-' + index"
              type="checkbox"
              v-model="selected[index]"
              :value="role.value"
            /> <label :for="'v-role-' + index">{{ role.text }}</label>
          </div>
          <div class="mt-2 mb-1" v-else>
            {{ role.text }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-2" md="12">
          <p>Rendszer értesítések:</p>
        </b-col>
        <b-col class="d-flex" style="margin-bottom:5px" v-for="(notification, index) in notifications" :key="index" md="12">
          <b-form-checkbox
            :id="'v-notification-' + index"
            v-model="selectedNotification[index]"
            :value="notification.value"
            :options="notification.value"
          /> <label :for="'v-notification-' + index">{{ notification.text }}</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-2" md="12">
          <p>Viber értesítések:</p>
        </b-col>
        <b-col class="d-flex" style="margin-bottom:5px" v-for="(notification, index) in viber_notifications" :key="index" md="12">
          <b-form-checkbox
            :id="'v-notification-' + index+notifications.length"
            v-model="selectedNotification[index+notifications.length]"
            :value="notification.value"
            :options="notification.value"
          /> <label :for="'v-notification-' + index+notifications.length">{{ notification.text }}</label>
        </b-col>
      </b-row>
      <p class="row justify-content-between">
        <button
          class="mr-2 btn"
          @click="$bvModal.hide('modal-edit')"
        >
          Mégse
        </button>
        <button
          class="mr-2 btn btn-primary"
          @click="saveUser"
        >
          Mentés
        </button>
      </p>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormSelect, BDropdown, BDropdownItem, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BFormFile,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
  },
  data() {
    return {
      file: null,
      email: '',
      password: '',
      phone: '',
      name: '',
      selected: [],
      selectedID: '',
      role: [
        { value: 'User', text: 'User' },
        { value: 'Admin', text: 'Admin' },
      ],
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Név',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés E-mailre',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      roles: [
        { text: 'HR' },
        { text: 'Felhasználók kezelése (szerkesztés, létrehozás)', value: 'user' },
        { text: 'Alvállalkozók kezelése (szerkesztés, létrehozás)', value: 'subcontractor' },
        { text: 'Dokumentumok elérése', value: 'documents_list' },
        { text: 'Szabadságok felületét eléri', value: 'freeday_menu' },
        { text: 'Alkalmazottak felületét eléri', value: 'employee_menu' },
        { text: 'Extra mezőket hozhat létre', value: 'extra_fields' },
        { text: 'Jelenléti íveket eléri', value: 'accounting' },
        { text: 'Riportok részt eléri', value: 'report' },
        { text: 'Építés vezető akinek van jogosultsága szabadnapot elbírálni', value: 'foreman_freeday' },
        { text: 'Vezető akinek van jogosultsága szabadnapot elbírálni', value: 'leader_freeday' },
        { text: 'Pénzügy' },
        { text: 'Levonások elérése kezelése', value: 'private_comment' },
        { text: 'Új levonás létrehozás', value: 'payments_create' },
        { text: 'Számlák feldolgozása', value: 'invoice_progress' },
        { text: 'Hitelezés kezelése (létrehozás/szerkesztés)', value: 'credit_menu' },
        { text: 'Rendelések' },
        { text: 'Rendelések menüpont', value: 'order_menu' },
        { text: 'Rendelés leadás', value: 'create_order' },
        { text: 'Minden rendelést elér', value: 'progress_order' },
        { text: 'Rendelést lehet hozzá rendelni (raktáros)', value: 'order_target' },
        { text: 'Rendeléshez tud ajánlatot feltölteni (árajánlatot tud csatolni a rendeléshez)', value: 'upload_offer' },
        { text: 'Rendeléshez elutasítására jogosultság', value: 'decline_offer' },
        { text: 'Rendelés kiszállítására jogosultság', value: 'delivery_offer' },
        { text: 'Rendelésel kapcsolatos számlák elérése', value: 'invoices_offer' },
        { text: 'Készlet feltöltés elbírálója lehet', value: 'storage_role' },
        { text: 'Készlet feltöltést adhat le', value: 'restocking_role' },
        { text: 'Általános' },
        { text: 'Bejelentkezés', value: 'dashboard' },
        { text: 'Feladatkezelő', value: 'todo' },
        { text: 'Összes feladat elérése', value: 'super-todo' },
        { text: 'Projektek kezelése', value: 'project' },
        { text: 'Ajánlat létrehozása', value: 'create_offer' },
        { text: 'Plusz munka elfogadása', value: 'extra_work' },
        { text: 'Árajánlat elfogadása', value: 'accept_order' },
        { text: 'Viber konfiguráció', value: 'viber' },
        { text: 'Jármű kezelés', value: 'car_menu' },
      ],
      notifications: [
        { text: 'Új feladatot kapott (email)', value: 'new_task' },
        { text: 'Kiadott feladat elkészült (email)', value: 'task_done' },
        { text: 'Lejárt projekt mérföldkövekről értesítés', value: 'project_down' },
        { text: 'Értesítés az új bejövő számlákról', value: 'invoice_notification' },
        { text: 'Értesítés az új belsős rendelésekről és státusz változásokról', value: 'order_inside' },
        { text: 'Értesítés az új külsős rendelésekről és státusz változásokról', value: 'order_outside' },
        { text: 'Értesítés az új ajánlatokról', value: 'offer_notification' },
        { text: 'Értesítés az új levonásokról', value: 'payment_notification' },
        { text: 'Értesítés az lejáró teljesítési igazolásokról', value: 'project_tig_notification' },
        { text: 'Értesítés az új bejövő rendeléshez tartozó árajánlatokról', value: 'new_order_offer' },
        { text: 'Új rendeléssel kapcsolatos számlákról üzenet', value: 'new_invices_offer_message' },
        { text: 'Bank garancia ügyintézés értesítés', value: 'invoice_notification_bank' },
        { text: 'Értesítés bejövő szabadságigényekről (építés vezető)', value: 'freeday_notification_foreman' },
        { text: 'Értesítés bejövő szabadságigényekről (vezető)', value: 'freeday_notification_leader' },
        { text: 'Értesítés a feldolgozott szabadságigényekről', value: 'freeday_notification_done' },
        { text: 'Alkalmazott gyereke betölti a 17. élet évét', value: 'became_an_adult' },
        { text: 'Orvosi alkalmassági lejárati értesítés', value: 'medical_notification' },
        { text: 'Munkavédelmi vizsga lejárati értesítés', value: 'osh_notification' },
        { text: 'Értesítés a lejáró jármű biztosításokról', value: 'car_down' },
        { text: 'Értesítés a lejáró extra alkalmazotti mezőkről', value: 'extra_notification' },
        { text: 'Értesítés a raktári feltöltésekről', value: 'order_storage' },
        { text: 'Értesítés minden projekt készlet túllépésről', value: 'boss_stock_overrun' },
        { text: 'Értesítés az olyan projekt készlet túllépésekről az építés vezetőnek', value: 'foreman_stock_overrun' },
      ],
      viber_notifications: [
        { text: 'Feladatokról értesítés', value: 'viber_task' },
        { text: 'Lejárt projekt mérföldkövekről értesítés', value: 'viber_project_down' },
        { text: 'Értesítés az új bejövő számlákról', value: 'viber_invoice_notification' },
        { text: 'Értesítés az új belsős rendelésekről és státusz változásokról', value: 'viber_order_inside' },
        { text: 'Értesítés az új külsős rendelésekről és státusz változásokról', value: 'viber_order_outside' },
        { text: 'Értesítés az új ajánlatokról', value: 'viber_offer_notification' },
        { text: 'Értesítés az új levonásokról', value: 'viber_payment_notification' },
        { text: 'Értesítés az lejáró teljesítési igazolásokról', value: 'viber_project_tig_notification' },
        { text: 'Értesítés az új bejövő rendeléshez tartozó árajánlatokról', value: 'viber_new_order_offer' },
        { text: 'Új rendeléssel kapcsolatos számlákról üzenet', value: 'viber_new_invices_offer_message' },
        { text: 'Bank garancia ügyintézés értesítés', value: 'viber_invoice_notification_bank' },
        { text: 'Értesítés bejövő szabadságigényekről (építés vezető)', value: 'viber_freeday_notification_foreman' },
        { text: 'Értesítés bejövő szabadságigényekről (vezető)', value: 'viber_freeday_notification_leader' },
        { text: 'Értesítés a feldolgozott szabadságigényekről', value: 'viber_freeday_notification_done' },
        { text: 'Alkalmazott gyereke betölti a 17. élet évét', value: 'viber_became_an_adult' },
        { text: 'Orvosi alkalmassági lejárati értesítés', value: 'viber_medical_notification' },
        { text: 'Munkavédelmi vizsga lejárati értesítés', value: 'viber_osh_notification' },
        { text: 'Értesítés a lejáró jármű biztosításokról', value: 'viber_car_down' },
        { text: 'Értesítés a lejáró extra alkalmazotti mezőkről', value: 'viber_extra_notification' },
        { text: 'Értesítés a raktári feltöltésekről', value: 'viber_order_storage' },
        { text: 'Értesítés minden projekt készlet túllépésről', value: 'viber_boss_stock_overrun' },
        { text: 'Értesítés az olyan projekt készlet túllépésekről az építés vezetőnek', value: 'viber_foreman_stock_overrun' },
      ],
      selectedNotification: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-success',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-info',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.loadUserList()
  },
  methods: {
    uploadImage(id) {
      const formData = new FormData()
      formData.append('image', this.file)

      axios({
        method: 'post',
        url: `user/image/${id}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'A kép sikeresen frissítve lett!',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    onFileChange(e) {
      this.file = e.target.files || e.dataTransfer.files
    },
    loadUserData(id) {
      this.selected = []
      axios({
        method: 'get',
        url: `user/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.name = response.data.name
        this.email = response.data.email
        this.phone = response.data.phone

        this.roles.forEach((element, index) => {
          if (element.value) {
            if (response.data.role.includes(element.value)) {
              this.selected.push(element.value)
            } else {
              this.selected.push(false)
            }
          } else {
            this.selected.push(false)
          }
        })

        console.log(this.selected)

        this.notifications.forEach((element, index) => {
          if (response.data.notification.includes(element.value)) {
            this.selectedNotification[index] = element.value
          } else {
            this.selectedNotification[index] = false
          }
        })

        this.viber_notifications.forEach((element, index) => {
          if (response.data.notification.includes(element.value)) {
            this.selectedNotification[(this.notifications.length + index)] = element.value
          } else {
            this.selectedNotification[(this.notifications.length + index)] = false
          }
        })

        this.selectedID = response.data.id
      })
    },
    saveUser() {
      axios({
        method: 'put',
        url: `user/update/${this.selectedID}`,
        data: {
          name: this.name,
          email: this.email,
          role: this.selected,
          phone: this.phone,
          notification: this.selectedNotification,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Felhasználói fiók adatai frissítve lettek',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.loadUserList()
        this.$bvModal.hide('modal-edit')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    updatePassword() {
      axios({
        method: 'put',
        url: `user/update_password/${this.selectedID}`,
        data: {
          password: this.password,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'A jelszó frissítve lett',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$bvModal.hide('modal-edit')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    deleteThisUser(id) {
      axios({
        method: 'delete',
        url: `user/delete/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadUserList()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Felhasználói fiók sikeresen törölve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    loadUserList() {
      axios({
        method: 'get',
        url: 'user/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.rows = response.data
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style>
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after{
    content: "►" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before{
    content: "◄" !important;
  }
</style>
